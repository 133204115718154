import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AVAIBLE_LANGUAGES_OBJ, CommunicationService, ENVIRONMENT, Environment, ILanguageObject, UserService } from '@vericode/ui-library';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public userLoggedIn: boolean = false;
  public alert: boolean = false;
  public menuArray: any[] = [];
  public errorLogin = false;
  public userCompanyName = "";
  public userFullName = "";
  public companyLogo = "";
  public currentLanguage: string = "it";

  constructor (
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(AVAIBLE_LANGUAGES_OBJ) private languagesObj: ILanguageObject,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
  ) {

    this.translate.addLangs(Object.keys(languagesObj));
    this.translate.setDefaultLang('it');
    //@ts-ignore
    this.translate.use(this.userService.getCookie('_lang') || "it");

    console.log(this.translate.currentLoader);
    console.log(this.translate.translations);

    if (this.userLoggedIn = this.userService.isUserAuthenticated()) {
      this.userFullName = this.userService.getUserInfo().fullname;
      //this.router.navigateByUrl("/logistics/shipments");
      this.getUserDetails().subscribe((res) =>{
          this.userCompanyName = res['name'];
          this.companyLogo = res['logo'];
          this.createMenu();
      })
    } else {
      this.router.navigateByUrl("/login")
    }

  }

  changeLanguage(lang: string) {
    this.userService.changeLanguage(lang as "it" | "en")
  }

  createMenu() {
    const authorizations = this.userService.getUserInfo().authorizations.filter(a => a.authorized);
    if ( authorizations.some(e => e.code == "WEB" || e.code == "COMPANYADMIN") )
      this.menuArray.push(
        {
          mainMenu: 'menu.logisticEvent',
          translateTag: 'logisticEvent',
          open: true,
          subsMenu: [
            {
              name: 'menu.shipments',
              translateTag:'shipments',
              path: '#/logistics/shipments',
            },
            {
              name: 'menu.incomingShipments',
              translateTag:'incomingShipments',
              path: '#/logistics/incoming-shipments',
            },
            {
              name: 'menu.receipts',
              translateTag: 'receipts',
              path: '#/logistics/receiving'
            },
            /*
            {
              name: 'menu.bulk',
              translateTag: 'bulk',
              path: '#/logistics/bulk'
            }
            */
          ],
        },
        {
          mainMenu: 'menu.report',
          translateTag: 'report',
          open: true,
          subsMenu: [
            {
              name: 'menu.itemHistory',
              translateTag: 'itemHistory',
              path: '#/logistics/items'
            },
            {
              name: 'menu.viewVolumes',
              translateTag: 'viewVolumes',
              path: '#/logistics/volumes'
            }
          ]
        },
        {
          mainMenu: 'menu.registry',
          translateTag: 'registry',
          open: true,
          subsMenu: [
            {
              name: 'menu.locationReadPoints',
              translateTag: 'locationReadPoints',
              path: '#/logistics/location'
            },
            {
              name: 'menu.destinations',
              translateTag: 'destinations',
              path: '#/logistics/destinations'
            }
          ]
        },
      );

    if ( authorizations.some(e => e.code == "ADMIN" || e.code == "COMPANYADMIN") )
      if ( this.menuArray.length > 0) {
        this.menuArray[2].subsMenu.push({
          name: 'menu.user',
          translateTag: 'user',
          path: '#/admin/info',
        });
      } else {
        this.menuArray.push({
          mainMenu: 'menu.registry',
          translateTag: 'registry',
          open: false,
          subsMenu: [
            {
              name: 'menu.user',
              translateTag: 'user',
              path: '#/admin/info',
            }
          ]
        });
      }
  }

  getUserDetails() {
    return this.http.get(this.environment.baseUrl +  "/core/company", {headers: this.communicationService.getHttpHeaders()});
  }

}
