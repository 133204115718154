<div id="app-container">
  <div class="header-container" *ngIf="userLoggedIn">
      <tr-lib-header logoUrl="/assets/img/svg/vericode-traceability-logo.svg" companyName="{{userCompanyName}}" username="{{userFullName}}"></tr-lib-header>
  </div>
  <div class="side-container" *ngIf="userLoggedIn">
    <tr-lib-menu 
      companyLogo="{{ companyLogo ? 'data:image/png;base64,' + companyLogo : '../assets/img/preview-logo-azienda.png' }}" 
      companyName="{{userCompanyName}}" 
      [menuArray]="menuArray"
    ></tr-lib-menu>
  </div>
  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>
<tr-lib-alert></tr-lib-alert>