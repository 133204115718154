import { Component } from '@angular/core';
import { Router } from '@angular/router';
/* import { UserService } from 'src/app/services/user/user.service'; */

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(
    /* private service: UserService, */
    private router: Router
  ){
   /*  if(!this.service.getErrorLogin()){
      this.router.navigate(['']);
    } */
  }
}
