import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { UiLibraryModule, ENVIRONMENT, AVAIBLE_LANGUAGES, AVAIBLE_LANGUAGES_OBJ } from '@vericode/ui-library';

import { environment } from 'src/environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as PAGES from "./pages/index";
import { avaibleLanguagesObj } from 'src/languages/languages';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/ui-library-i18n/", suffix: ".json" },
    { prefix: "./assets/admin-app-i18n/", suffix: ".json" },
    { prefix: "./assets/login-app-i18n/", suffix: ".json" },
    { prefix: "./assets/logistics-app-i18n/", suffix: ".json" },
    { prefix: "./assets/wrapper-web-i18n/", suffix: ".json" },
  ]);
}
 
@NgModule({
  declarations: [ 
    AppComponent, 
    PAGES.ErrorComponent,
    PAGES.HomeComponent,
    PAGES.NotfoundComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    UiLibraryModule, 

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      }
    }),
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: AVAIBLE_LANGUAGES_OBJ, useValue: avaibleLanguagesObj },
    { provide: AVAIBLE_LANGUAGES, useValue: Object.entries(avaibleLanguagesObj).map(function (value) {
      return { id: value[0], ...value[1] }
    }) },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
