import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import * as PAGES from './pages/index';
import { AuthGuard } from './auth/authguard.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: "login",
        loadChildren: () => import('./wrappers/login-app-wrapper.module').then(m => m.LoginWrapperModule),
        data: {
          moduleName: "login",
        },
      },
      {
        path: "logistics",
        canActivate: [AuthGuard],
        loadChildren: () => import('./wrappers/logistic-app-wrapper.module').then(m => m.LogisticWrapperModule),
        data: {
          moduleName: "logistics",
        },
      },
      {
        path: "admin",
        canActivate: [AuthGuard],
        loadChildren: () => import('./wrappers/admin-app-wrapper.module').then(m => m.AdminWrapperModule),
        data: {
          moduleName: "admin",
        },
      }
    ]
  },
  {
    path: 'error',
    pathMatch: 'full',
    component: PAGES.ErrorComponent
  },
{
    path: '',
    pathMatch: 'full',
    redirectTo: 'logistics/shipments'
  },
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: 'logistics/shipments'
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PAGES.ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
