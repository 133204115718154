<div>
  <div class="card">
    <div class="icon-container">
      <div class="icon icon-warning big"></div>
    </div>
    <div class="error-title">
      <h1>{{'pageError.title' | translate}}</h1>
    </div>
    <div class="error-message">
      <p>{{'pageError.message' | translate}}</p>
    </div>
    <div class="button-container">
      <a href="https://login.vericode.kingsrl.com/">
        <div class="button btn-red btn-no-icon btn-full-w">
          {{'pageError.button' | translate}}
        </div>
      </a>
    </div>
  </div>
</div>
