import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@vericode/ui-library';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard {

  constructor (
    private userService: UserService, 
    private router: Router
  ) { }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.userService.isUserAuthenticated()) {
      return true;
    } else {
      this.router.navigateByUrl("/login");
      return false;
    }
  }

}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthguardGuard).canActivate(next, state);
}